import React from 'react'
import { Layout } from '../../containers/Layout'
import { Text } from '../../components/Text'
import { Separator } from '../../components/Separator'
import { MobileContent } from '../../components/grid/MobileContent'
import styled from 'styled-components/macro'
import rem from 'polished/lib/helpers/rem'
import { HtmlContent } from '../../components/HtmlContent/HtmlContent'
import { sizes } from '../../theme'

const Page = () => (
  <Layout>
    <Text size="h2" weight="light" uppercase center marginBottom="size8">
      Pago realizado con éxito :)
    </Text>
    <MobileContent>
      <Content>
        <HtmlContent>
          <p>En breve prepararé tu tarjeta regalo.</p>
          <p>
            Puedes pasarte a <strong>recoger en persona</strong> tu tarjeta
            regalo dentro del horario laboral.
          </p>
          <p>
            Si lo prefieres puedo <strong>mandarla por email</strong> a la
            persona que vaya a disfrutar de la tarjeta regalo. Para ello,
            escríbeme a{' '}
            <a href="mailto:codes@centrofeeling.es">codes@centrofeeling.es</a> o
            llámame o escríbeme al 699 762 837.
          </p>
        </HtmlContent>
      </Content>
    </MobileContent>
  </Layout>
)

const Content = styled.div`
  max-width: ${rem(600)};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${rem(sizes.size7)};

  text-align: center;
`

export default Page
